import { jsxs as _, jsx as l } from "react/jsx-runtime";
import { useEffect as g, useRef as x, useState as y } from "react";
import { Play as f } from "@vgno/icons";
import { classnames as m, formatEnDash as E } from "@vgno/utils";
import { BreakingIndicator as I } from "@vgno/core";
import '../assets/VideoTeaser.css';const M = "_title_1ngsn_1", N = "_video_1ngsn_8", z = "_small_1ngsn_12", P = "_medium_1ngsn_17", w = "_large_1ngsn_23", F = "_opinion_1ngsn_29", T = "_text_1ngsn_33", L = "_breakingPrefix_1ngsn_45", D = "_breakingIndicator_1ngsn_51", i = {
  title: M,
  video: N,
  small: z,
  medium: P,
  large: w,
  opinion: F,
  text: T,
  breakingPrefix: L,
  breakingIndicator: D
}, j = ({
  breakingNewsPrefix: r,
  hasBreakingIndicator: n,
  headlineType: t,
  isOpinion: e = !1,
  isVideo: o = !1,
  size: s,
  title: a
}) => {
  const c = `${t}`;
  return /* @__PURE__ */ _(
    c,
    {
      className: m(
        i.title,
        e && i.opinion,
        o ? ["title-medium", i.video] : i[s]
      ),
      children: [
        n && /* @__PURE__ */ l(I, { className: i.breakingIndicator }),
        r && /* @__PURE__ */ l("span", { className: i.breakingPrefix, children: `${r} ` }),
        /* @__PURE__ */ l("span", { className: i.text, children: E(a) })
      ]
    }
  );
}, H = (r) => {
  const n = Math.floor(r / 1e3), t = Math.floor(n / 3600), e = Math.floor(n % 3600 / 60), o = n % 60, s = t > 0 ? `${t}:` : "", a = `${e.toString().padStart(t > 0 ? 2 : 1, "0")}`, c = `${o.toString().padStart(2, "0")}`;
  return `${s}${a}:${c}`;
}, R = (r) => {
  const n = Math.floor(r / 1e3), t = Math.floor(n / 3600), e = Math.floor(n % 3600 / 60), o = n % 60, s = [];
  return t > 0 && s.push(`${t} time${t !== 1 ? "r" : ""}`), e > 0 && s.push(`${e} minutt${e !== 1 ? "er" : ""}`), o > 0 && s.push(`${o} sekund${o !== 1 ? "er" : ""}`), s.join(", ");
};
function V(r, n, t) {
  g(() => {
    if (!r && (n != null && n.current)) {
      const e = () => {
        t != null && t.current && (t == null || t.current.play());
      }, o = () => {
        t != null && t.current && (t == null || t.current.pause());
      }, s = n.current;
      return s.addEventListener("mouseenter", e), s.addEventListener("mouseleave", o), () => {
        s.removeEventListener("mouseenter", e), s.removeEventListener("mouseleave", o);
      };
    }
  }, [r]);
}
function v(r, n, t, e) {
  g(() => {
    if (r && (n != null && n.current)) {
      const o = (a) => {
        a.forEach((c) => {
          c.isIntersecting && !e && (t != null && t.current) ? t.current.play() : t != null && t.current && t.current.pause();
        });
      }, s = new IntersectionObserver(o, {
        threshold: 0.5
      });
      return s.observe(n.current), () => {
        n.current && s.unobserve(n.current);
      };
    }
  }, [r, n, t]);
}
function B(r, n) {
  g(() => {
    if (r) {
      let t;
      const e = () => {
        n(!0), clearTimeout(t), t = setTimeout(() => {
          n(!1);
        }, 150);
      };
      return window.addEventListener("scroll", e), () => {
        window.removeEventListener("scroll", e), clearTimeout(t);
      };
    }
  }, [r]);
}
const O = "_article_z9lsf_1", Y = "_content_z9lsf_6", q = "_video_z9lsf_18", A = "_link_z9lsf_22", C = "_durationPill_z9lsf_32", G = "_duration_z9lsf_32", J = "_titleFlex_z9lsf_51", u = {
  article: O,
  content: Y,
  video: q,
  link: A,
  durationPill: C,
  duration: G,
  titleFlex: J
}, tt = ({
  className: r = "",
  contentType: n,
  duration: t,
  headlineType: e,
  isOpinion: o,
  onClick: s,
  title: a,
  url: c,
  videoPreviewURL: b,
  ...$
}) => {
  const d = window.matchMedia("(max-width: 720px)").matches, h = x(null), p = x(null), [k, S] = y(!1);
  return V(d, p, h), B(d, S), v(d, p, h, k), /* @__PURE__ */ l(
    "article",
    {
      className: m(u.article, r),
      ref: p,
      ...$,
      children: /* @__PURE__ */ _(
        "a",
        {
          className: m("label-primary", u.link),
          "data-use-overlay-player": n === "video",
          href: c.toString(),
          onClick: s ? () => s : () => {
          },
          children: [
            b && /* @__PURE__ */ _(
              "video",
              {
                autoPlay: !1,
                className: m(u.video),
                loop: !0,
                muted: !0,
                playsInline: !0,
                ref: h,
                children: [
                  /* @__PURE__ */ l("source", { src: b, type: "video/mp4" }),
                  "Your browser does not support the video tag."
                ]
              }
            ),
            /* @__PURE__ */ _(
              "div",
              {
                className: m(u.content, "label-small", "label-primary"),
                children: [
                  /* @__PURE__ */ l("div", { className: u.titleFlex, children: /* @__PURE__ */ l(
                    j,
                    {
                      headlineType: e,
                      isOpinion: o,
                      isVideo: !0,
                      size: "medium",
                      title: a
                    }
                  ) }),
                  /* @__PURE__ */ _("div", { className: u.durationPill, children: [
                    /* @__PURE__ */ l(f, {}),
                    t && t > 0 ? /* @__PURE__ */ l("small", { className: m(u.duration, "label-small"), children: H(t ?? 0) }) : void 0
                  ] })
                ]
              }
            )
          ]
        }
      )
    }
  );
};
export {
  j as T,
  tt as V,
  R as a,
  H as f
};

import { jsx as e, jsxs as _ } from "react/jsx-runtime";
import { FriendlyTimestamp as I } from "@vgno/core";
import { Play as K, Pullquote as Q } from "@vgno/icons";
import { mergeStyles as U, classnames as t, getSrcSet as q, getHotspot as W } from "@vgno/utils";
import { V as X, a as Y, f as Z, T as C } from "./chunks/VideoTeaser.js";
import './assets/Teaser.css';function aa(n) {
  const l = Math.ceil(n);
  if (l > 60) {
    const c = Math.floor(l / 60), i = l % 60;
    return `${c} t ${i} min lesetid`;
  }
  return `${l} min lesetid`;
}
const ea = "_article_9vanb_1", la = "_author_9vanb_5", na = "_avatar_9vanb_16", ta = "_content_9vanb_25", sa = "_small_9vanb_35", ia = "_medium_9vanb_39", ra = "_large_9vanb_43", oa = "_overlay_9vanb_44", ca = "_figure_9vanb_51", ma = "_image_9vanb_55", ua = "_kicker_9vanb_66", _a = "_label_9vanb_67", da = "_link_9vanb_75", va = "_opinion_9vanb_123", pa = "_metaData_9vanb_129", ba = "_meta_9vanb_129", ha = "_name_9vanb_151", fa = "_paywall_9vanb_157", ga = "_picture_9vanb_161", ya = "_duration_9vanb_187", Na = "_play_9vanb_191", wa = "_pullquote_9vanb_206", ka = "_timestamp_9vanb_215", Sa = {
  article: ea,
  author: la,
  avatar: na,
  content: ta,
  small: sa,
  medium: ia,
  large: ra,
  overlay: oa,
  figure: ca,
  image: ma,
  kicker: ua,
  label: _a,
  link: da,
  opinion: va,
  metaData: pa,
  meta: ba,
  name: ha,
  paywall: fa,
  picture: ga,
  duration: ya,
  play: Na,
  pullquote: wa,
  timestamp: ka
}, xa = {
  large: "(min-width: 580px) 580px, 100vw",
  medium: "(min-width: 580px) 580px, 100vw",
  small: "300px"
}, Da = {
  column: "Meninger",
  editorial: "Leder",
  "op-ed": "Meninger"
}, za = {
  dinepenger: "Dine Penger",
  vg: "VG+"
}, Aa = ({ author: n, styles: l }) => {
  var m;
  const c = n.title, { value: i } = ((m = n.contacts) == null ? void 0 : m.find(
    ({ type: r }) => r === "title"
  )) || {
    value: "Journalist"
  };
  return /* @__PURE__ */ _("div", { className: t("label-small", "label-primary", l.author), children: [
    n.imageAsset && /* @__PURE__ */ e(
      "img",
      {
        alt: `Bilde av ${c}`,
        className: l.avatar,
        loading: "lazy",
        sizes: "48px",
        src: n.imageAsset.urls[0].url,
        srcSet: q(n.imageAsset)
      }
    ),
    /* @__PURE__ */ e("p", { className: l.name, children: c }),
    i && /* @__PURE__ */ e("p", { children: i })
  ] });
}, Va = ({
  alt: n,
  authors: l,
  breakingNewsPrefix: c,
  className: i = "",
  contentType: m = "article",
  duration: r,
  fetchpriority: P,
  hasBreakingIndicator: $,
  headlineType: f = "h3",
  imageAsset: s,
  kicker: g,
  loading: j,
  newsroom: H = "vg",
  onClick: p,
  overlay: V = !1,
  paywall: O = !1,
  queryParams: B = {},
  readTimeMinutes: b,
  showTimestamp: E = !0,
  size: F = "medium",
  styles: G = {},
  timestamp: d,
  title: y,
  type: v = "news",
  url: J,
  videoPreviewURL: N = void 0,
  // if exists then we need to show a video teaser
  ...w
}) => {
  var D, z, A, M;
  const a = U(Sa, G), L = Object.entries(B), h = new URL(J), k = l ? l[0] : void 0;
  for (const [R, T] of L)
    h.searchParams.set(R, T);
  const o = ["column", "editorial", "op-ed"].includes(v), u = v === "feature" ? "large" : F, S = u === "large" || o && u === "medium" || V, x = d !== void 0 && Ma(d);
  return N ? /* @__PURE__ */ e(
    X,
    {
      className: i,
      contentType: m,
      duration: r,
      headlineType: f,
      isOpinion: o,
      onClick: p,
      title: y,
      url: h,
      videoPreviewURL: N,
      ...w
    }
  ) : /* @__PURE__ */ e(
    "article",
    {
      ...S && {
        "data-color-scheme": "dark"
      },
      className: t(
        a.article,
        a[u],
        o && a.opinion,
        (o || S) && a.overlay,
        i
      ),
      ...w,
      children: /* @__PURE__ */ _(
        "a",
        {
          className: t("label-primary", a.link),
          "data-use-overlay-player": m === "video",
          href: h.toString(),
          onClick: p ? () => p : void 0,
          children: [
            ((z = (D = s == null ? void 0 : s.urls) == null ? void 0 : D[0]) == null ? void 0 : z.url) && /* @__PURE__ */ _("picture", { className: a.picture, children: [
              /* @__PURE__ */ e(
                "img",
                {
                  alt: n || "",
                  className: a.image,
                  fetchpriority: P,
                  height: (A = s.size) == null ? void 0 : A.height,
                  loading: j,
                  sizes: xa[u],
                  src: s.urls[0].url,
                  srcSet: q(s),
                  style: {
                    objectPosition: W(s, "initial")
                  },
                  width: (M = s.size) == null ? void 0 : M.width
                }
              ),
              m === "video" && r !== void 0 && /* @__PURE__ */ _("div", { className: a.play, children: [
                /* @__PURE__ */ e(K, { "aria-hidden": !0 }),
                r > 0 ? /* @__PURE__ */ e(
                  "small",
                  {
                    "aria-label": `Video på ${Y(r)}`,
                    className: t(a.duration, "label-small"),
                    children: Z(r)
                  }
                ) : void 0
              ] })
            ] }),
            /* @__PURE__ */ _(
              "div",
              {
                className: t(a.content, "label-small", "label-primary"),
                children: [
                  o && /* @__PURE__ */ e(
                    Q,
                    {
                      "aria-label": "Meninger ikon",
                      className: a.pullquote
                    }
                  ),
                  o && (v === "column" && k ? /* @__PURE__ */ e(Aa, { author: k, styles: a }) : /* @__PURE__ */ e("p", { className: a.label, children: Da[v] })),
                  g && /* @__PURE__ */ e(
                    "p",
                    {
                      className: t(
                        a.kicker,
                        `label-${u === "large" ? "primary" : "secondary"}`
                      ),
                      children: g
                    }
                  ),
                  /* @__PURE__ */ e(
                    C,
                    {
                      breakingNewsPrefix: c,
                      hasBreakingIndicator: $,
                      headlineType: f,
                      isOpinion: o,
                      size: u,
                      title: y
                    }
                  ),
                  /* @__PURE__ */ _("ul", { className: a.metaData, children: [
                    O && /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e("span", { className: t(a.paywall, a.meta), children: za[H] }) }),
                    d && E && /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e(
                      I,
                      {
                        className: t(a.timestamp, a.meta),
                        options: {
                          day: "numeric",
                          hour: x ? !1 : void 0,
                          minute: x ? !1 : void 0,
                          month: "short",
                          weekday: !1
                        },
                        timestamp: d
                      }
                    ) }),
                    b !== void 0 && b > 0 && /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e("span", { className: t(a.meta, "label-small"), children: aa(b) }) })
                  ] })
                ]
              }
            )
          ]
        }
      )
    }
  );
};
function Ma(n) {
  return (Date.now() - new Date(n).getTime()) / 36e5 > 3;
}
export {
  Va as Teaser,
  xa as imageSizes
};

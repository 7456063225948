import { jsxs as c, jsx as e } from "react/jsx-runtime";
import { Button as m } from "@vgno/core";
import { classnames as n } from "@vgno/utils";
import './assets/RelatedTags.css';const d = "_tags_1tn2m_1", g = "_title_1tn2m_9", r = {
  tags: d,
  title: g
}, _ = ({
  className: i,
  newsroom: l = "vg",
  tags: s,
  title: a = "Relaterte emner"
}) => {
  if (s.length === 0)
    return null;
  const o = l === "vg";
  return /* @__PURE__ */ c(
    "section",
    {
      className: n(
        "background-secondary font-inter label-secondary",
        r.tags,
        i
      ),
      children: [
        /* @__PURE__ */ e(
          "h3",
          {
            className: n(
              "font-weight-semi-bold label-medium",
              r.title
            ),
            children: a
          }
        ),
        /* @__PURE__ */ e("ul", { children: s.map((t) => /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e(
          m,
          {
            href: o ? `/tag/${t.slug}` : `/${l}/tag/${t.slug}`,
            style: "gray",
            children: t.title
          }
        ) }, t.id)) })
      ]
    }
  );
};
export {
  _ as RelatedTags,
  _ as default
};
